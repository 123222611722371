/* General Container */
.sms-chat-container {
    width: 100%;
    max-width: 300px; /* Same as WhatsApp UI */
    height: 515px; /* Same as WhatsApp UI */
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    border-radius: 16px; /* Matches the WhatsApp UI */
    overflow: hidden;
    border: 1px solid #ddd;
  }
  
  /* Header */
 .sms-chat-container .sms-chat-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    background-color: #007AFF;
    color: white;
  }
  
 .sms-chat-container .back-button {
    background: none;
    border: none;
    margin-right: 10px;
    cursor: pointer;
  }
  
 .sms-chat-container .profile-icon {
    margin-right: 10px;
  }
  
 .sms-chat-container .header-info {
    display: flex;
    flex-direction: column;
  }
  
 .sms-chat-container .contact-name {
    font-weight: bold;
    font-size: 13px;
  }
  
 .sms-chat-container .status {
    font-size: 11px;
    color: #d4e8e5;
  }
  
  /* Chat Content */
 .sms-chat-container .sms-chat-content {
    flex: 1;
    padding: 10px;
    overflow-y: auto; /* Enables scrolling */
    display: flex;
    flex-direction: column;
    background-color: #e9edef; /* Matches WhatsApp UI */
  }
  
  /* Message Bubble */
 .sms-chat-container .message-bubble {
    max-width: 70%;
    padding: 10px 15px;
    margin: 4px 8px;
    border-radius: 15px;
    line-height: 1.4;
    font-size: 13px;
  }
  
  .sms-chat-container .incoming {
    align-self: flex-start;
    background-color: #ffffff;
    color: #000;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .sms-chat-container .outgoing {
    align-self: flex-end;
    background-color: #007AFF;
    color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Message Text */
 .sms-chat-container .message-text {
    margin: 0;
    word-wrap: break-word;
  }
  
  /* Read More Button */
  .sms-chat-container .read-more-button {
    background: none;
    border: none;
    color: #007AFF;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
  
  /* Message Input */
  .sms-chat-container .sms-message-input {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
  }
  
  .sms-chat-container .sms-message-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 13px;
    margin-right: 10px;
  }
  
  .sms-chat-container .send-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  