@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #13becf;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #13becf;
}

body {
  background-color: #f9f9f9;
}

.pagination {
  margin-top: 10px;
}

.pagination .MuiTablePagination-toolbar {
  padding: 0;
}

.pagination .MuiTablePagination-spacer {
  display: none;
}

.pagination .MuiTablePagination-displayedRows {
  display: none;

  /* font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #445A68; */
}

.pagination .MuiTablePagination-toolbar {
  justify-content: center;
  gap: 12px;
}

.pagination .MuiTablePagination-selectLabel {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #445a68;
}

.pagination .MuiInputBase-root {
  margin-right: 0;
  margin-left: 0;
  border-radius: 4px;
}

.pagination .MuiSelect-select {
  background: linear-gradient(180deg, #13becf 0%, #455869 100%);
  box-shadow: 1px 1px 2.8px 0px rgba(16, 25, 52, 0.25);
  border-radius: 4px;
  padding: 8px;
}

.pagination .MuiInputBase-root.Mui-focused .MuiSelect-select {
  border-radius: 4px;
}

.pagination .MuiTablePagination-actions {
  margin-left: 9px !important;
}

.pagination .MuiInputBase-input {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}

.pagination .MuiSelect-icon {
  color: #fff;
}

.pagination .Mui-disabled {
  background-color: transparent !important;
}

.pagination .MuiIconButton-root {
  background: linear-gradient(180deg, #13becf 0%, #455869 100%);
  box-shadow: 1px 1px 2.8px 0px rgba(16, 25, 52, 0.25);
  padding: 6px;
  border-radius: 4px;
  margin-left: 9px;
}

.pagination
  .MuiTablePagination-actions
  .MuiIconButton-root
  + .MuiIconButton-root {
  margin-left: 7px;
}

.pagination .MuiSvgIcon-root {
  fill: #fff;
}

.pagination .MuiIconButton-root.Mui-disabled {
  background: #cccccc !important;
  color: #666666 !important;
}

.pagination .pagination-icon-button {
  background: linear-gradient(180deg, #13becf 0%, #455869 100%);
  box-shadow: 1px 1px 2.8px 0px rgba(16, 25, 52, 0.25);
  padding: 6px;
  border-radius: 4px;
  color: #fff;
}

.pagination .pagination-icon-button.Mui-disabled {
  background: #cccccc !important;
  color: #666666 !important;
}

.pagination .pagination-icon-button + .pagination-icon-button {
  margin-left: 7px;
}

/* General Container */
.chat-container {
  width: 100%;
  max-width: 300px;
  height: 515px;
  background-color: #e9edef;
  /* border-radius: 16px; */
  overflow: hidden;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
}

/* Header */
.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: #005e53;
  color: white;
}

.header-left {
  display: flex;
  align-items: center;
}

.back-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  margin-right: 6px;
  cursor: pointer;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 4px;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-name {
  font-weight: bold;
  font-size: 13px;
}

.status {
  font-size: 11px;
  color: #d4e8e5;
}

.header-icons .icon-button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  margin-left: 8px;
  cursor: pointer;
}

.chat-content {
  flex: 1;
  overflow-y: auto; /* Enables scrolling */
  padding: 10px;
  background-color: #ebf7ff;
  /* margin-top: 10px;
  margin-bottom: 70px; */
}

.message-text.expanded {
  max-height: none; /* Remove height restriction when expanded */
  display: block;
}

/* Read More Button */
.read-more-button {
  background: none;
  border: none;
  color: #13becf;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
}

/* Message Bubble */
.message-bubble {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 10px 15px;
  margin: 4px 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: start;
}

.message-bubble .footer-text {
  color: #7c7c7c;
}

.message-image-placeholder {
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}
.message-image-placeholder .header-message {
  text-align: left;
}

.message-image {
  width: 100%;
  max-width: 180px;
  border-radius: 8px;
  display: block;
}


/* Message Text */
.message-text {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
  line-height: 1.6;
  word-break: break-all;
}

.message-text.collapsed {
  max-height: 6em; /* Limit for collapsed text */
}

.message-text.expanded {
  max-height: none; /* Allow full text to show */
}

.message-links {
  display: block;
  margin-top: 4px;
}

.message-links a {
  display: block;
  text-align: center;
  border-top: 1px solid #f4f4f4;
  padding: 10px 0px;
  color: #13becf;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
}

.message-links a:hover {
  text-decoration: underline;
}

.message-input-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 4px;
  background-color: #ebf7ff;
}

.message-input-container .input {
  display: flex;
  gap: 1rem;
  padding: 10px;
  background: #fff;
  border-radius: 30px;
}

.message-input-container input {
  all: unset;
  max-width: 105px;
  font-size: 13px;
}

.message-input-container .btn {
  background: none;
  border: none;
  width: 49px;
  padding: 0px;
}

.Toastify__toast-container {
  z-index: 9999;
}